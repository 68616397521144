import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import SmartPostAlbumSelect from './SmartPostAlbumSelect.jsx';
import {strCompareIgnoreCase, getUUID} from './util.jsx';
import AttrPicker from './AttrPicker.jsx';

class SmartPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            genAlbums: [],
            applying: false,
            error: null,
            albumTemplate: '',
            previewAlbumIdx: null,
            willUpload: {},
            filterHelpShown: false,
            templateHelpShown: false,
        };

        this.filterAddClick = this.filterAddClick.bind(this);
        this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
        this.handleSimpleInputChange = this.handleSimpleInputChange.bind(this);
        this.onApplyClick = this.onApplyClick.bind(this);
        this.onPreviewClick = this.onPreviewClick.bind(this);
    }

    render() {
        const filters = this.state.filters.map((filter, idx) => {
            const radioName = `filterInclude_${idx}`;
            return (
                <div key={filter.key} className="row">
                  <div className="col-md-8">
                    <AttrPicker creatable={false}
                                onChangeName={data => this.handleFilterInputChange(data.value, idx, "name")}
                                onChangeValue={data => this.handleFilterInputChange(data.value, idx, "value")}
                    />
                  </div>
                  <div className="col-md-4" style={{position: "relative", right: "20px"}}>
                    <label className="radio-inline">
                      <input type="radio"
                             name={radioName}
                             value="include"
                             checked={filter.mode == "include"}
                             onChange={e => this.handleFilterInputChange(e.target.value, idx, "mode")} /> Include
                    </label>
                    <label className="radio-inline">
                      <input type="radio"
                             name={radioName}
                             value="exclude"
                             checked={filter.mode == "exclude"}
                             onChange={e => this.handleFilterInputChange(e.target.value, idx, "mode")} /> Exclude
                    </label>
                    <span onClick={this.onRemoveClick.bind(this, idx)}
                          style={{marginLeft: "5px"}}
                          type="button"
                          className="btn btn-default btn-xs"><span className="glyphicon glyphicon-remove"></span>
                    </span>
                  </div>
                </div>
            );
        });

        const albumsList = this.state.genAlbums.map((genAlbum, idx) => {
            const numItems = genAlbum.items.length;
            let preview = null;
            if (this.state.previewAlbumIdx === idx) {
                const images = genAlbum.items.map(item => {
                    return <a key={item.item_pk}
                              target="_blank"
                              href={item.url}>
                      <img src={item.image_url} style={{width: '100px'}} />
                    </a>;
                });
                preview = (
                    <div>
                      {images}
                      <p className="text-small text-muted">Not necessarily in this order</p>
                    </div>
                );
            }
            const hiddenInputs = genAlbum.items.map(item => {
                const iptName = `smartCustomItem_${idx}`;
                return <input key={item.item_pk}
                              type="hidden"
                              name={iptName}
                              value={item.item_pk} />;
            });
            const style = {
                padding: "5px",
                margin: "20px",
                background: this.state.willUpload.hasOwnProperty(idx)
                          ? "#dff0d8"
                          : "#f2dede",
                boxShadow: "#c0c0c0 0 0 20px",
            };
            return (
                <div key={genAlbum.name} style={style}>
                  <div>
                    <small>Generated name: <strong>{genAlbum.name}</strong></small>
                  </div>
                  <SmartPostAlbumSelect groupAlbums={this.props.groupAlbums}
                                        candidate={genAlbum.name}
                                        inputGroupKey={idx}
                                        onChange={this.onAlbumSelectChange.bind(this, idx)} />
                  {hiddenInputs}
                  <div>
                    {numItems} items (<a href="#" onClick={(e) => this.onPreviewClick(e, idx)}>preview</a>) {preview}
                  </div>
                </div>
            );
        });

        const filterHelp = document.getElementById("filters-help-tpl").innerHTML;
        const templateHelp = document.getElementById("album-name-template-help-tpl").innerHTML;

        return (
            <div>
              <fieldset className="form-inline">
                <h4>
                  Filters <i className="fa fa-question-circle text-small text-muted"
                             style={{cursor: 'pointer', position: 'relative', bottom: '3px'}}
                             onClick={() => this.setState({filterHelpShown: !this.state.filterHelpShown})}></i>
                </h4>
                <div style={{display: this.state.filterHelpShown ? 'block' : 'none'}}
                     dangerouslySetInnerHTML={{__html: filterHelp}}></div>
                {filters}
                <button type="button"
                        className="btn btn-default"
                        style={{marginTop: "5px"}}
                        onClick={this.filterAddClick}><i className="glyphicon glyphicon-plus"></i> Add filter</button>
                <h4>
                  Album name template <i className="fa fa-question-circle text-small text-muted"
                                         style={{cursor: 'pointer', position: 'relative', bottom: '3px'}}
                                         onClick={() => this.setState({templateHelpShown: !this.state.templateHelpShown})}></i>
                </h4>
                <div style={{display: this.state.templateHelpShown ? 'block' : 'none'}}
                     dangerouslySetInnerHTML={{__html: templateHelp}}></div>
                <input type="text" className="form-control" name="albumTemplate"
                       style={{width: "300px"}}
                       placeholder={`Example: {Collection} - {${StrUserInfo.words.style.toLowerCase()}} - {${StrUserInfo.words.size.toLowerCase()}}`}
                       onChange={this.handleSimpleInputChange} />
              </fieldset>
              <button type="button"
                      className="btn btn-primary btn-lg"
                      style={{marginTop: "10px"}}
                      onClick={this.onApplyClick}>
                Compute albums {this.state.applying &&
                                <i className="fa fa-refresh fa-spin"></i>}
              </button>
              {this.state.error &&
               <p className="alert alert-danger">{this.state.error}</p>}
              {albumsList.length > 0 &&
               <div>
                 <h5>Albums</h5>
                 <p className="text-muted">Please double-check all automatic selections before posting.</p>
                 {albumsList}
               </div>
              }
            </div>
        );
    }

    handleFilterInputChange(value, idx, propToSet) {
        const filter = update(this.state.filters[idx], {
            $merge: {
                [propToSet]: value,
            },
        });
        this.setState({
            filters: update(this.state.filters, {
                $splice: [[idx, 1, filter]],
            }),
        });
    }

    handleSimpleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    filterAddClick() {
        this.setState({
            filters: update(this.state.filters, {
                $push: [{
                    name: '',
                    value: '',
                    mode: 'include',
                    key: getUUID(),
                }],
            }),
        });
    }

    onRemoveClick(idx) {
        this.setState({
            filters: update(this.state.filters, {
                $splice: [[idx, 1]],
            }),
        });
    }

    onApplyClick() {
        this.setState({
            applying: true,
            genAlbums: [],
            previewAlbumIdx: null,
            willUpload: {},
        });
        const url = '/api/v1/smartie/';
        const data = {
            filters: JSON.stringify(this.state.filters),
            albumTemplate: this.state.albumTemplate,
        };
        const self = this;
        $.post(url, data, function(res) {
            self.setState({applying: false, error: ''});
            if (!res.success) {
                self.setState({error: res.message});
                return;
            }
            self.setState({
                genAlbums: res.albums
                              .sort((a, b) => strCompareIgnoreCase(a.name, b.name)),
            });
        }).fail(function(xhr, status, error) {
            self.setState({
                applying: false,
                error: xhr.responseText,
            });
        });
    }

    onPreviewClick(event, idx) {
        event.preventDefault();
        this.setState({
            previewAlbumIdx: this.state.previewAlbumIdx === idx ? null : idx,
        });
    }

    onAlbumSelectChange(idx, isUpload) {
        if (isUpload) {
            this.setState({
                willUpload: update(this.state.willUpload, {
                    $merge: { [idx]: true },
                }),
            });
        } else {
            this.setState({
                willUpload: update(this.state.willUpload, {
                    $unset: [idx],
                }),
            });
        }
    }
}

SmartPost.propTypes = {
    groupAlbums: PropTypes.array.isRequired,
};

function SmartPostApp(el, groupAlbums) {
    if (el === null)
        return;
    /* by setting a transient id as the key of the root component we're
       guaranteeing that the component will be thrown away and re-rendered
       every time this function is called.  This is an easy, lazy,
       good-enough-for-who-it's-for way to ensure that there isn't any
       weird, residual state leftover from the last call. */
    const id = Date.now();
    const root = createRoot(el);
    root.render(<SmartPost groupAlbums={groupAlbums} key={id} />);
}

export default SmartPostApp;
