import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { MDXEditor } from '@mdxeditor/editor/MDXEditor';
import { headingsPlugin } from '@mdxeditor/editor/plugins/headings';
import { listsPlugin } from '@mdxeditor/editor/plugins/lists';
import { linkPlugin } from '@mdxeditor/editor/plugins/link';
import { linkDialogPlugin } from '@mdxeditor/editor/plugins/link-dialog';
import { imagePlugin } from '@mdxeditor/editor/plugins/image';
import { toolbarPlugin } from '@mdxeditor/editor/plugins/toolbar';
import { UndoRedo } from '@mdxeditor/editor/plugins/toolbar/components/UndoRedo';
import { BoldItalicUnderlineToggles } from '@mdxeditor/editor/plugins/toolbar/components/BoldItalicUnderlineToggles';
import { BlockTypeSelect } from '@mdxeditor/editor/plugins/toolbar/components/BlockTypeSelect';
import { ListsToggle } from '@mdxeditor/editor/plugins/toolbar/components/ListsToggle';
import { CreateLink } from '@mdxeditor/editor/plugins/toolbar/components/CreateLink';
import { InsertImage } from '@mdxeditor/editor/plugins/toolbar/components/InsertImage';
import { stripHtml } from 'string-strip-html';
import { makeCodemode, isCodemode, stripCodemode } from './codeMode.js';
import MarkdownView from './MarkdownView.jsx';
import StrModal from './StrModal.jsx';

import { strPostFormData } from './util.jsx';

import 'style-loader!css-loader!@mdxeditor/editor/style.css';
import styles from './css/MarkdownEditor.css';

const hasHtml = (markdown) => {
    // Pretty brutish :grimacing:
    /* TODO: Update MDXEditor and rip this out since it doesn't seem to work on old browsers */
    return markdown !== stripHtml(markdown).result;
};

const MarkdownEditor = (props) => {
    const pmarkdownisCodeMode = isCodemode(props.markdown);

    const { onChange, onSave, onCancel } = props;
    const [imageError, setImageError] = useState("");
    const [markdown, setMarkdown] = useState(
        pmarkdownisCodeMode ? stripCodemode(props.markdown) : props.markdown
    );
    const [markdownCoded, setMarkdownCoded] = useState(
        pmarkdownisCodeMode ? props.markdown : makeCodemode(props.markdown)
    );
    const [isCodeEditor, setIsCodeEditor] = useState(pmarkdownisCodeMode);
    const editorRef = useRef(null);
    const [isEditorRefSet, setIsEditorRefSet] = useState(false);
    const [showingSwitchConfirm, setShowingSwitchConfirm] = useState(false);

    if (onChange && (onSave || onCancel)) {
        throw new Error("onChange is mutually exclusive with onSave and onCancel");
    }
    if (!!onSave !== !!onCancel) {
        throw new Error("onSave and onCancel must both be provided together");
    }

    const onEditorChange = (md) => {
        setMarkdown(md);
        setMarkdownCoded(makeCodemode(md));
        if (onChange)
            onChange(md);
    };

    const onCodeModeTextAreaChange = (e) => {
        const mdUncoded = e.target.value;
        const mdCoded = makeCodemode(mdUncoded);
        setMarkdown(mdUncoded);
        setMarkdownCoded(mdCoded);
        if (onChange)
            onChange(mdCoded);
    };

    const setEditorRef = (node) => {
        editorRef.current = node;
        if (node)
            setIsEditorRefSet(true);
    };

    useEffect(() => {
        if (!isCodeEditor && editorRef.current)
            editorRef.current.setMarkdown(stripHtml(markdown).result);
        // Since we only want to execute this on a mode change,
        // we don't care markdown dep
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCodeEditor, isEditorRefSet]);

    const onSaveClick = (e) => {
        e.preventDefault();
        onSave(isCodeEditor ? markdownCoded : markdown);
    };

    const onCancelClick = (e) => {
        e.preventDefault();
        onCancel();
    };

    const toolbarContents = () => (
        <React.Fragment>
          <UndoRedo />
          <BoldItalicUnderlineToggles />
          <BlockTypeSelect />
          <ListsToggle />
          <CreateLink />
          <InsertImage />
        </React.Fragment>
    );

    const imageUploadHandler = async (imageFile) => {
        const url = "/api/v2/user_image_upload/";
        const formData = new FormData();
        formData.append("image", imageFile);
        const source = {
            source: props.source,
            ...(props.sourceData || {}),
        };
        formData.append("source", JSON.stringify(source));
        const rsp = await strPostFormData(url, formData);
        const json = await rsp.json();
        if (rsp.ok) {
            return json.url;
        } else {
            setImageError("Upload error: " + json.error);
            return null;
        }
    };

    const switchToRichEditor = (e) => {
        e.preventDefault();
        if (hasHtml(markdown)) {
            setShowingSwitchConfirm(true);
        } else {
            setIsCodeEditor(false);
        }
    };

    const knowledgeUrl = 'https://support.sonlet.com/support/solutions/articles/16000096218-how-do-i-add-a-custom-page-header-to-my-party-page-';

    return (
        <div>
          <StrModal isOpen={showingSwitchConfirm}
                    onClose={() => setShowingSwitchConfirm(false)}>
            <div style={{width: "100%", maxWidth: "500px"}}>
              <h4>Heads-up!</h4>
              <p>
                You have custom HTML in your announcement, which will be lost
                when you switch to the rich editor. Would you like to proceed
                or cancel?
              </p>
            </div>
            <div className="btn-group">
              <button type="button"
                      onClick={() => setShowingSwitchConfirm(false)}
                      className="btn btn-default">Cancel</button>
              <button type="button"
                      onClick={() => {
                          setIsCodeEditor(false);
                          setShowingSwitchConfirm(false);
                      }}
                      className="btn btn-primary">Yes, switch modes</button>
            </div>
          </StrModal>
          {!isCodeEditor &&
           <MDXEditor
               ref={setEditorRef}
               markdown={stripHtml(props.markdown).result}
               plugins={[
                   toolbarPlugin({ toolbarContents }),
                   headingsPlugin(),
                   listsPlugin(),
                   linkPlugin(),
                   linkDialogPlugin(),
                   imagePlugin({ imageUploadHandler }),
               ]}
               onChange={onEditorChange}
               contentEditableClassName="markdown-editor-content"
           />
          }
          {imageError && <p className="text-error">{imageError}</p>}
          {!isCodeEditor &&
           <div className="text-muted text-small" style={{marginLeft: '15px', marginTop: '15px'}}>
             <p style={{marginBottom: "0"}}>
               Tip: you can paste images into the editor with
               Control-V (or Command-V on Mac).
             </p>
             <p>
               Want to use more advanced formatting? Click here to try the &nbsp;
               <mark onClick={() => setIsCodeEditor(true)}
                     style={{cursor: 'pointer'}}>
                 <input type="checkbox" />
                 {' '}Code Editor
               </mark>.
             </p>
           </div>
          }
          {isCodeEditor &&
           <div>
             <p className="text-muted text-small"
                style={{marginTop: '15px'}}>
               <span>
                 You&apos;re using the
                 {' '}
                 <mark onClick={switchToRichEditor}
                       style={{cursor: 'pointer'}}>
                   <input type="checkbox" defaultChecked />
                   {' '}Code Editor
                 </mark>.
               </span>
               {' '}
               Review the supported
               {' '}
               <a target="_blank" href="/markdown/">markdown formatting</a>
               {' '}
               for the editor. Unfamiliar with Markdown? Read instructions on the
               {' '}
               <a target="_blank" href={knowledgeUrl}>knowledge base</a>.
             </p>
             <textarea className="form-control"
                       rows="7"
                       value={markdown}
                       name="markdown"
                       onChange={onCodeModeTextAreaChange} />
             <h6>Live Preview:</h6>
             <MarkdownView markdown={markdownCoded} />
           </div>
          }
          {props.onSave &&
           <div className="btn-group markdown-editor-actions">
             <button className="btn btn-primary"
                     onClick={onSaveClick}>
               Save <i className="fa fa-floppy-o"></i>
             </button>
             <button className="btn btn-default"
                     onClick={onCancelClick}>
               Cancel
             </button>
           </div>
          }
        </div>
    );
};

MarkdownEditor.propTypes = {
    // Shan't be codeMode
    markdown: PropTypes.string.isRequired,
    // If onChange is provided, no save/cancel buttons will be displayed.
    // If onChange is not given, onSave and onCancel should be provided.
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    source: PropTypes.oneOf([
        "user-bio",
        "party-announcement",
        "invoice-settings",
        "party-join-rules",
    ]).isRequired,
    sourceData: PropTypes.object,
};

export default MarkdownEditor;
