import React from 'react';
import { createRoot } from 'react-dom/client';
import FacebookLoginRequired from './FacebookLoginRequired.jsx';
import FacebookGroupSelector from './FacebookGroupSelector.jsx';
import FacebookAlbumsCleanupList from './FacebookAlbumsCleanupList.jsx';

class FbCleanup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fbIsReady: false,
            group: null,
        };
        this.loggedInCb = this.loggedInCb.bind(this);
        this.groupSelected = this.groupSelected.bind(this);
    }

    render() {
        return (
            <FacebookLoginRequired loggedInCb={this.loggedInCb}>
              <div className="row">
                <div className="col-md-6">
                  <h3>Step 1: Pick a Facebook group</h3>
                  <p>
                    Which Facebook group would you like to cleanup?
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <FacebookGroupSelector
                          fbIsReady={this.state.fbIsReady}
                          groupSelected={this.groupSelected}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {this.state.group &&
               <div className="row">
                 <div className="col-md-6">
                   <h3>Step 2: Select albums to unlink from {this.state.group.name}</h3>
                   <FacebookAlbumsCleanupList group={this.state.group} />
                 </div>
               </div>
              }
            </FacebookLoginRequired>
        );
    }

    loggedInCb(response) {
        this.setState({fbIsReady: true});
    }

    groupSelected(group) {
        this.setState({group});
    }
}

/**
 * el should have a data-item-id attribute.
 */
function FbCleanupApp(el) {
    const root = createRoot(el);
    root.render(<FbCleanup />);
}

export default FbCleanupApp;
