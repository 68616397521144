import React from 'react';
import { createRoot } from 'react-dom/client';
import FbPickGroup from './FbPickGroup.jsx';


function FbPickGroupApp(el, props) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<FbPickGroup {...props} />);
}

export default FbPickGroupApp;
