import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { groupBy } from 'lodash-es';
import { updateStateThing } from '../util.jsx';
import FacebookLoginRequired from "../FacebookLoginRequired.jsx";
const { Option } = components;


const optionShape = {
    icon: PropTypes.string,
    image: PropTypes.string,
    label: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const ImageOption = (props) => (
    <Option {...props}>
      <img style={smallStyle} src={props.data.image} />
        {' '}{props.data.label}
    </Option>
);

ImageOption.propTypes = {
    data: PropTypes.shape(optionShape),
};

const SelectLabel = (props) => (
    <span>
      <img style={smallStyle} src={props.data.image} />{' '}
      {props.data.label}
    </span>
);

SelectLabel.propTypes = {
    data: PropTypes.shape(optionShape),
};


const SimpleRefresh = (props) => {
    let refreshCls = "glyphicon glyphicon-refresh";
    if (props.loading)
        refreshCls += " glyphicon-spin";
    const dropDownTitle = <span className={refreshCls}></span>;
    return (
        <div style={{display: "flex"}}>
          <div style={{flex: "initial"}}>
            <button className="btn btn-default" onClick={props.onClick}>{dropDownTitle}</button>
          </div>
          <div style={{flex: "initial", marginLeft: "10px"}}>
            {props.error &&
             <div className="alert alert-danger">{props.error}</div>
            }
            <div className="text-muted text-small">
              <div className="inline-block">Joined/left Facebook groups/pages lately?</div>
              <div className="inline-block">Click this button to sync up!</div>
            </div>
            <div className="text-muted text-small">
              Please remember to
              {' '}
              <a target="_blank" href="https://support.sonlet.com/support/solutions/articles/16000083315-add-the-sonlet-app-to-your-facebook-group-video-">add the Sonlet app to your group</a>
              {' '}.
            </div>
          </div>
        </div>
    );
};

SimpleRefresh.propTypes = {
    onClick: PropTypes.func.isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
};


const smallStyle = {
    maxHeight: '20px',
    maxWidth: '20px',
    verticalAlign: 'text-bottom',
};


class MultiSocialPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            socialTargets: {},
            shouldRefresh: false,
            options: this.mapChannelsToOptions({}),
            value: '',
            loading: false,
            fbResponse: props.fbResponse,
        };
        this.updateStateThing = updateStateThing.bind(this);
        this.loggedInCb = this.loggedInCb.bind(this);
    }

    fetch(refresh=false) {
        this.setState({loading: true});
        const data = {};
        if (this.state.fbResponse) {
            data.access_token = this.state.fbResponse.authResponse.accessToken;
        }
        if (refresh)
            data.refresh = 1;
        const getTargetsCb = (rsp) => {
            if (!rsp.success) {
                this.setState({error: 'Failed to refresh Facebook info: ' + rsp.message});
            } else {
                const channelList = rsp.data;
                const channelsByType = groupBy(channelList, 'type');
                this.setState({
                    options: this.mapChannelsToOptions(channelsByType),
                    loading: false,
                });
            }
        };
        if (this.props.getTargets) {
            this.props.getTargets(data, getTargetsCb);
        } else {
            $.post('/get_consultant_social_targets/', data, getTargetsCb);
        }
    }

    componentDidMount() {
        this.fetch();
    }

    mapChannelsToOptions(channelsByType) {
        const channelTypes = {
            'FACEBOOKGROUP': {
                name: 'Facebook groups',
                icon: 'fa fa-facebook',
            },
            'FACEBOOKPAGE': {
                name: 'Facebook pages',
                icon: 'fa fa-facebook',
            },
        };
        const types = Object.keys(channelsByType);

        let options = this.props.defaultOptions.slice();

        types.forEach((type) => {
            options.push({
                label: channelTypes[type].name,
                isDisabled: true,
            });
            const typeOptions = channelsByType[type].map(channel => {
                return {
                    value: channel.pk,
                    label: channel.name,
                    image: channel.image_thumbnail,
                    icon: channelTypes[type].icon,
                };
            });
            options = options.concat(typeOptions);
        });

        return options;
    }

    loggedInCb(response) {
        this.setState({fbResponse: response});
    }

    render() {
        const {options, loading, fbResponse, error} = this.state;

        return (
            <div>
              <FacebookLoginRequired loggedInCb={this.loggedInCb}>
                {fbResponse &&
                 <SimpleRefresh onClick={() => this.fetch(true)}
                                loading={loading}
                                error={error}
                 />
                }
              </FacebookLoginRequired>
              {this.props.showLabel &&
               <label style={{fontSize: '10px'}}> Posting to:</label>
              }
              <Select
                  isMulti={this.props.isMulti}
                  className="groupSelect"
                  classNamePrefix="groupSelect"
                  placeholder={`Select destination${this.props.isMulti ? 's' : ''}`}
                  defaultValue={[options[0]]}
                  options={options}
                  onChange={this.props.onChange}
                  components={{
                      Option: ImageOption,
                      MultiValueLabel: SelectLabel,
                      SingleValue: SelectLabel,
                  }}
              />
            </div>
        );
    }
}

MultiSocialPicker.defaultProps = {
    isMulti: true,
    showLabel: true,
    defaultOptions: [],
};

MultiSocialPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    defaultOptions: PropTypes.array,
    fbResponse: PropTypes.object,
    isMulti: PropTypes.bool,
    getTargets: PropTypes.func,
    showLabel: PropTypes.bool,
};

export default MultiSocialPicker;
