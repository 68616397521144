import { includes, escape, truncate } from 'lodash-es';
import React, { useState, useEffect, useRef } from 'react';

function strCompareIgnoreCase(a, b) {
    a = a.toUpperCase();
    b = b.toUpperCase();
    if (a < b)
        return -1;
    if (a > b)
        return 1;
    return 0;
}

function extractSummaryInfo(items) {
    let partyMap = {};

    /* uniquify parties */
    for (const item of items) {
        for (const party of item.party_set) {
            if (partyMap.hasOwnProperty(party.pk))
                continue;
            partyMap[party.pk] = party;
        }
    }

    /* ...and collect into a sorted array */
    let parties = [];
    for (const pk in partyMap) {
        parties.push(partyMap[pk]);
    }
    parties = parties.sort((a, b) => strCompareIgnoreCase(a.name, b.name));

    /* uniquify albums and groups */
    let albums = {};
    let groups = {};
    let queuedAlbums = {};
    let queuedGroups = {};
    for (const item of items) {
        for (const postinfo of item.fbpostinfo_set) {
            const album = postinfo.fb_album;
            const album_pk = album.pk;
            if (!albums.hasOwnProperty(album_pk)) {
                const group = album.fb_group;
                const group_pk = group.pk;
                albums[album_pk] = {
                    'pk': album_pk,
                    'name': `${group.name} / ${album.name}`,
                };
                if (!groups.hasOwnProperty(group_pk)) {
                    groups[group_pk] = {
                        'pk': group_pk,
                        'name': group.name,
                    };
                }
            }

            if (postinfo.post_later) {
                if (!queuedAlbums.hasOwnProperty(album_pk)) {
                    const group = album.fb_group;
                    const group_pk = group.pk;
                    queuedAlbums[album_pk] = {
                        'pk': album_pk,
                        'name': `${group.name} / ${album.name}`,
                    };
                    if (!queuedGroups.hasOwnProperty(group_pk)) {
                        queuedGroups[group_pk] = {
                            'pk': group_pk,
                            'name': group.name,
                        };
                    }
                }
            }
        }
    }

    // we used an object above to ensure no dupe albums were added.
    // Now we have to make it into an array, which we can then sort
    // by name.
    const toSortedArray = (sourceObject) => {
        let returnArray = [];
        for (const key of Object.keys(sourceObject)) {
            returnArray.push(sourceObject[key]);
        }
        returnArray.sort((a, b) => strCompareIgnoreCase(a.name, b.name));
        return returnArray;
    };

    return {
        'parties': parties,
        'albums': toSortedArray(albums),
        'groups': toSortedArray(groups),
        'queuedAlbums': toSortedArray(queuedAlbums),
        'queuedGroups': toSortedArray(queuedGroups),
    };
}

const _currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

// 2500 -> $2,500.00
function formatCurrency(value) {
    return _currencyFormatter.format(value);
}

function currencyStrToNum(currency) {
    return Number(currency.replace(/[^0-9.-]+/g,""));
}

function itemPassesFilters(item, filters, excludeThisFilter=false) {
    if (Object.keys(filters).length === 0)
        return true;
    if (!(item.data && item.data.attributes))
        return true;
    const attrs = item.data.attributes;
    const attrKeys = attrs.map(a => a.name);
    for (const fk in filters) {
        if (!includes(attrKeys, fk))
            return false;
        let cur_result = true;
        for (const attr of attrs) {
            if (fk === attr.name) {
                if (!includes(filters[fk], attr.value)) {
                    cur_result = false;
                } else {
                    if (excludeThisFilter) {
                        /* if we are in the same attribute name as
                         * excludeThisFilter then the values must match
                         * exactly for the overall filter to pass. This
                         * enables behavior where selecting multiple
                         * values within the same filter name group will
                         * not make their counts additive, they are still
                         * separate but also factor in filters from other
                         * name groups*/
                        if ((excludeThisFilter[0] === fk &&
                             excludeThisFilter[1] === attr.value) ||
                            excludeThisFilter[0] !== fk) {
                            cur_result = true;
                        } else {
                            cur_result = false;
                        }
                    } else {
                        cur_result = true;
                        break;
                    }
                }
            }
        }
        if (cur_result === false)
            return false;
    }
    return true;
}

function successOrRedirect(response) {
    if (response.status < 400)
        return true;
    window.location.href = "/accounts/login/?next=" + window.location.pathname;
    return false;
}

/**
 * Highlights @mentions and #432 item numbers.
 */
function stylify(text) {
    let safeText = escape(text);
    safeText = safeText.replace(/\B(@)([a-z0-9._@+-]+)/gi,
                                '<a href="/u/$2/">$&</a>');
    let words = [];
    for (let word of safeText.split(' ')) {
        const matches = word.match(/^#([0-9]+)[:,]*$/);
        if (matches) {
            const itemId = matches[1];
            const link = `<a href="/i/${itemId}/">${word}</a>`;
            words.push(link);
        } else {
            words.push(word);
        }
    }
    return words.join(' ');
}

/* Starting to differ from stylify which is used for chats so
 * separating now, I think the goal should be to bring these back
 * together at some point to pull party chat into socialism*/
function socialStylify(text) {
    return escape(text)
        .replace(/\B(@)([a-z0-9._@+-]+)/gi,
                 '<a href="/u/$2/">$&</a>')
        .replace(/(?:^|\s)(?:#)([A-Za-z0-9_]+)/gi, (match, tag) => {
            return `<a href="/tag/${tag.toLowerCase()}/">${match}</a>`;
        });
}

/*
 * based on https://stackoverflow.com/a/1500501/209050
 *
 * truncate - when a number is provided, truncate the URL text to that width
 */
function urlify(text, truncateLength=null) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
        const urlText = truncateLength
                      ? truncate(url, { length: truncateLength })
                      : url;
        return '<a target="_blank" href="' + url + '">' + urlText + '</a>';
    });
}

/**
 * Most of the fetch/strRequest APIs take a `signal` parameter,
 * which can be used to abort the fetch request (should be the
 * .signal property of an AbortController).
 *
 * In class-based components the new AbortController is typically
 * created in componentDidMount, and the .abort() method is called
 * from componentWillUnmount.
 *
 * In function-based components the new AbortController is typically
 * created at the top of the useEffect setup function doing the
 * network request, and the .abort() method is called from the cleanup
 * function for the useEffect setup function (the function returned by
 * the setup function).
 */

/**
 * fetch with same-origin credentials and return JSON
 */
async function fetchJSON(url, signal = null, searchParams = null) {
    try {
        if (searchParams && Object.keys(searchParams).length > 0)
            url += '?' + new URLSearchParams(searchParams);
        const result = await fetch(url, {credentials: 'same-origin', signal: signal});
        if (!result.ok) {
            throw new Error(`HTTP error ${result.status}`);
        }
        return await result.json();
    } catch (error) {
        if (error.name !== 'AbortError') {
            throw error;
        }
        return null;
    }
}

/**
 *
 * fetch with same-origin credentials.  Returns an array with:
 * [jsonResponse, responseObject]
 *
 * jsonResponse is the result of await responseObject.json()
 * responseObject is a Response [1]
 *
 * [1] https://developer.mozilla.org/en-US/docs/Web/API/Response
 */
async function fetchJSONWithResponse(url, signal = null) {
    try {
        const result = await fetch(url, {credentials: 'same-origin', signal: signal});
        return [await result.json(), result];
    } catch (error) {
        if (error.name !== 'AbortError') {
            throw error;
        }
        return [null, null];
    }
}

async function strRequest(url, method, data, sendJSON = true, signal = null) {
    const contentType = sendJSON
                      ? "application/json"
                      : "application/x-www-form-urlencoded";
    const body = sendJSON
               ? JSON.stringify(data)
               : new URLSearchParams(data);
    try {
        return await fetch(url, {
            credentials: 'same-origin',
            method: method,
            body: body,
            signal: signal,
            headers: {
                "X-CSRFToken": Cookies.get(strConfig.csrfCookieName),
                "Accept": "application/json",
                "Content-Type": contentType,
            },
        });
    } catch (error) {
        if (error.name !== 'AbortError') {
            throw error;
        }
        return null;
    }
}

async function strRequestJSON(url, method, data, signal = null) {
    const rsp = await strRequest(url, method, data, true, signal);
    if (rsp === null)
        return null;
    return await rsp.json();
}

async function strRequestJSONWithResponse(url, method, data, signal = null) {
    const rsp = await strRequest(url, method, data, true, signal);
    if (rsp === null)
        return [null, null];
    let jsonData;
    try {
        jsonData = await rsp.json();
    } catch (error) {
        // Invalid JSON, return an empty object
        jsonData = {};
    }
    return [jsonData, rsp];
}

async function strPost(url, data, sendJSON = true, signal = null) {
    return strRequest(url, "POST", data, sendJSON, signal);
}

async function strPostJSON(url, data, signal = null) {
    return strRequestJSON(url, "POST", data, signal);
}

async function strPostJSONWithResponse(url, data, signal = null) {
    return strRequestJSONWithResponse(url, "POST", data, signal);
}

async function strPostFormData(url, formData) {
    // Allow contentType to be set automatically
    return await fetch(url, {
        credentials: "same-origin",
        method: "POST",
        body: formData,
        headers: {
            "X-CSRFToken": Cookies.get(strConfig.csrfCookieName),
            "Accept": "application/json",
        },
    });
}

/**
 * Converts a standard DRF error response to a single string for display
 */
function errorsToString(responseJSON) {
    return Object.keys(responseJSON)
                 .map(f => f + ': ' + responseJSON[f].join(', '))
                 .join('\n');
}

const USER_INFO_CHANGE_EVENT_NAME = 'str-user-info-changed';

function triggerUserInfoChanged() {
    window.dispatchEvent(new Event(USER_INFO_CHANGE_EVENT_NAME));
}

function getUUID() {
    // https://gist.github.com/jed/982883
    function b(a) {
        return a ? (a^Math.random()*16>>a/4).toString(16)
             : ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b);
    }
    return b();
}

function updateStateThing(e, callback=null) {
    const target = e.target;
    const name = target.name;
    const value = (
        (target) => {
            switch (target.type) {
            case 'checkbox':
                return target.checked;
            case 'number':
                return Number(target.value);
            default:
                return target.value;
            }
        }
    )(target);
    this.setState({[name]: value}, callback);
}

function clamp(min=0, max=Number.POSITIVE_INFINITY) {
    // Returns an event handler which limits input values to save to state.
    return (e) => {
        let value;
        if (e.target.value === '')
            value = e.target.value;
        else
            value = Math.min(Math.max(e.target.value, min), max);
        this.setState({[e.target.name]: value});
    };
}

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function styleSizeCustomLabel(styleOrSize) {
    const custom = `${styleOrSize.source !== 'company' ? ' (custom)' : ''}`;
    return `${styleOrSize.name}${custom}`;
}

// based on https://usehooks.com/useDebounce/ (adapted for functions)
function useDebounce(fn, value, delay) {
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(fn, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [fn, value, delay] // Only re-call effect if fn or delay changes
    );
}

function filterOutEmoji(string) {
    // https://stackoverflow.com/a/63464318/5473188
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Character_Classes
    return string.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
}

function moveInArray(arr, oldIndex, newIndex) {
    // https://stackoverflow.com/a/5306832
    arr.splice(
        newIndex,
        0,
        arr.splice(oldIndex, 1)[0],
    );
    return arr;
}

// https://stackoverflow.com/a/31751827/209050
function setIdleTimeout(millis, onIdle, onUnidle, onStartIdleTimer) {
    var timeout = 0;
    startTimer();

    function startTimer() {
        timeout = setTimeout(onExpires, millis);
        document.addEventListener("mousemove", onActivity);
        document.addEventListener("keydown", onActivity);
        document.addEventListener("touchstart", onActivity);
        if (onStartIdleTimer) {
            onStartIdleTimer(timeout);
        }
    }

    function onExpires() {
        timeout = 0;
        onIdle();
    }

    function onActivity() {
        if (timeout) {
            clearTimeout(timeout);
        } else {
            onUnidle();
        }
        //since the mouse is moving, we turn off our event hooks for 1 second
        document.removeEventListener("mousemove", onActivity);
        document.removeEventListener("keydown", onActivity);
        document.removeEventListener("touchstart", onActivity);
        setTimeout(startTimer, 1000);
    }
}

function pluralize(what, howMany, plural = null) {
    if (howMany === 1)
        return what;
    return plural ? plural : (what + "s");
}

export {
    strCompareIgnoreCase,
    extractSummaryInfo,
    formatCurrency,
    currencyStrToNum,
    itemPassesFilters,
    successOrRedirect,
    stylify,
    socialStylify,
    urlify,
    fetchJSON,
    fetchJSONWithResponse,
    strRequest,
    strRequestJSON,
    strRequestJSONWithResponse,
    strPost,
    strPostJSON,
    strPostJSONWithResponse,
    strPostFormData,
    errorsToString,
    USER_INFO_CHANGE_EVENT_NAME,
    triggerUserInfoChanged,
    getUUID,
    updateStateThing,
    clamp,
    useInterval,
    styleSizeCustomLabel,
    useDebounce,
    filterOutEmoji,
    moveInArray,
    setIdleTimeout,
    pluralize,
};
