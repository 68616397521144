import React from 'react';
import PropTypes from 'prop-types';

class FacebookLoginRequired extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            response: {
                status: null,
            },
        };
        this.statusChange = this.statusChange.bind(this);
        this.fbButtonClick = this.fbButtonClick.bind(this);
    }

    render() {
        const status = this.state.response.status;

        if (status === null) {
            if (this.props.quiet)
                return null;
            return (
                <div>
                  <i className="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                  <div className="text-muted">Loading...</div>
                </div>
            );
        }

        const loginButton = (
            <img src="https://str-static.s3.amazonaws.com/static/images/fb-login.png"
                 style={{width: '180px', cursor: 'pointer'}}
                 onClick={this.fbButtonClick}></img>

        );

        let rest = null;
        if (status === 'connected') {
            rest = <div>{this.props.children}</div>;
        } else if (status === 'not_authorized') {
            rest = <div>Please login to this app</div>;
        } else {
            rest = <div>Please login to Facebook</div>;
        }

        return (
            <div>
              {status === 'connected' || loginButton}
              {rest}
            </div>
        );
    }

    componentDidMount() {
        const self = this;
        window.fbAsyncInit.strFbLoaded.done(function() {
            FB.getLoginStatus(function(response) {
                self.statusChange(response);
            });
        });
    }

    statusChange(response) {
        // The response object is returned with a status field that lets the
        // app know the current login status of the person.
        // Full docs on the response object can be found in the documentation
        // for FB.getLoginStatus().
        this.setState({response});
        if (response.status === 'connected') {
            var data = {'token': response.authResponse.accessToken};
            const tokenUpdateCb = (rsp) => {
                if (!rsp.success)
                    console.error("fbtokenupdate failed"); // eslint-disable-line no-console

                /* our token is now persisted on our backend, go ahead with callback */
                if (this.props.loggedInCb) {
                    this.props.loggedInCb(response);
                }
            };
            if (this.props.updateToken) {
                this.props.updateToken(data, tokenUpdateCb);
            } else {
                $.getJSON('/accounts/fbtokenupdate/', data, tokenUpdateCb);
            }
        }
    }

    fbButtonClick() {
        if (this.state.response.status !== 'connected') {
            FB.login(this.statusChange, {
                scope: 'email,pages_manage_metadata,pages_manage_posts,pages_read_engagement,publish_to_groups,pages_show_list',
            });
        }
    }
}

FacebookLoginRequired.propTypes = {
    children: PropTypes.node,
    updateToken: PropTypes.func,
    loggedInCb: PropTypes.func,
    /* don't show the loading spinner */
    quiet: PropTypes.bool,
};

export default FacebookLoginRequired;
