import React, { useState, useEffect, useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import moment from 'moment';
import StrSelect from './StrSelect.jsx';
import { fetchJSON, strRequest } from './util.jsx';

const supportArticleUrl = "https://support.sonlet.com/support/solutions/articles/16000187038-using-the-sonlet-user-plus-browser-extension-for-facebook-posting";

const SUPFbPickCanvas = ({onPostSuccess}) => {
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedAlbum, setSelectedAlbum] = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    const [selectedItemPks, setSelectedItemPks] = useState([]);
    const [supIsConnected, setSupIsConnected] = useState(false);
    const [refreshMessage, setRefreshMessage] = useState("");
    const [postMessage, setPostMessage] = useState("");
    const [showHelp, setShowHelp] = useState(false);

    const fetchGroupsInfo = useCallback(async () => {
        try {
            const data = await fetchJSON('/api/v2/sup_get_groups_info/');
            setGroups(data.results);
        } catch (error) {
            console.error('Error fetching groups info:', error);
        }
    }, []);

    useEffect(() => {
        // if we already had a group selected, refresh that selection
        // (since the new version of the group might now have more albums,
        // different name, etc.).
        if (selectedGroup) {
            const updatedGroup = groups.find(g => g.fbid === selectedGroup.fbid) || null;
            if (updatedGroup !== selectedGroup) {
                setSelectedGroup(updatedGroup);
            }
        }
        // ditto
        if (selectedGroup && selectedAlbum) {
            const updatedAlbum = selectedGroup.albums.find(a => a.fbid === selectedAlbum.fbid) || null;
            if (updatedAlbum !== selectedAlbum) {
                setSelectedAlbum();
            }
        }
    }, [groups, selectedGroup, selectedAlbum]);

    // check for SUP connection
    useEffect(() => {
        const sleep = (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds));
        let handlerInstalled = false;
        const handlePong = (event) => {
            if (event.source === window && event.data.action === "pong") {
                setSupIsConnected(true);
                window.removeEventListener("message", handlePong);
                handlerInstalled = false;
            }
        };
        setTimeout(() => {
            window.postMessage({action: "ping"}, "*");
        }, 2000);
        window.addEventListener("message", handlePong);
        handlerInstalled = true;
        return () => {
            if (handlerInstalled)
                window.removeEventListener("message", handlePong);
        };
    }, []);

    useEffect(() => {
        fetchGroupsInfo();

        const handleExtensionMessage = (event) => {
            if (event.source === window) {
                const action = event.data.action;
                if (action === "postImagesToAlbumResults") {
                    const { success, message } = event.data;
                    setIsPosting(false);
                    if (success) {
                        toast.success(message);
                        setPostMessage(message);
                        onPostSuccess();
                    } else {
                        toast.error(message);
                        setPostMessage(message);
                    }
                    return;
                }
                if (action === "refreshAlbumsForFbGroupResults") {
                    const { success, message } = event.data;
                    setIsRefreshing(false);
                    setRefreshMessage("");
                    if (success) {
                        toast.success(message);
                        fetchGroupsInfo();
                    } else {
                        toast.error(message);
                    }
                    return;
                }
            }
        };

        const handleOnSelectedItemPks = (event) => setSelectedItemPks(event.detail.itemPks);

        window.addEventListener("message", handleExtensionMessage);
        window.addEventListener("onSelectedItemPks", handleOnSelectedItemPks);

        return () => {
            window.removeEventListener("onSelectedItemPks", handleOnSelectedItemPks);
            window.removeEventListener("message", handleExtensionMessage);
        };
    }, [fetchGroupsInfo, onPostSuccess]);

    const handleGroupChange = (groupOption) => {
        setSelectedGroup(groups.find((group) => group.fbid === groupOption.value));
        setSelectedAlbum(null);
    };

    const handleAlbumChange = (albumOption) => {
        setSelectedAlbum(selectedGroup.albums.find(a => a.fbid === albumOption.value));
    };

    const handleRefreshAlbums = () => {
        setIsRefreshing(true);

        // Countdown with instructions to leave FB tab open
        const updateMsg = (cnt) => setRefreshMessage(`Opening a Facebook tab in ${cnt} second${cnt === 1 ? "": "s"}. Please leave it open and visible until it closes.`);
        let countdown = 5;
        updateMsg(countdown);
        let intervalId = setInterval(() => {
            if (countdown === 1) {
                clearInterval(intervalId);
                setRefreshMessage("Please leave the Facebook tab open and visible until it closes.");
                window.postMessage({
                    action: "refreshAlbumsForFbGroup",
                    fbGroupId: selectedGroup.fbid,
                }, "*");
            } else {
                updateMsg(--countdown);
            }
        }, 1000);
    };

    const handlePost = async () => {
        setIsPosting(true);
        setPostMessage("");
        try {
            // TODO: maybe this should just be /api/v2/items/ so that we can
            // get more info about each item for captionizing purposes
            const rsp = await strRequest("/api/v2/get_item_images/", "POST", {item_pks: selectedItemPks});
            if (!rsp.ok) {
                setPostMessage("Failed to get item images: " + rsp.statusText);
                setIsPosting(false);
                return;
            }
            const json = await rsp.json();
            // json.results is an array of {"pk", itemPk, "image_url": url}
            const fbImages = json.results.map(info => ({
                url: info.image_url,
                // TODO: captionize (might need more item deets, see TODO about API endpoint above)
                caption: "",
            }));
            // Response to this request comes in the form of a message with
            // action=postImagesToAlbumResults (handled by handleExtensionMessage)
            window.postMessage({
                action: "postImagesToAlbum",
                fbAlbumId: selectedAlbum.fbid,
                fbImages,
            }, "*");
        } catch (error) {
            setPostMessage("Failed to get item images: " + error);
            console.log("Failed to get item images", error);
            setIsPosting(false);
            return;
        }
    };

    const renderLastRefreshed = (group) => {
        const timestamp = group.last_refreshed_albums;
        if (!timestamp)
            return `Albums haven't been fetched for ${group.name}. Please refresh albums.`;
        return `Last refreshed ${moment(timestamp).fromNow()}`;
    };

    const renderHelp = () => {
        return (
            <div className="alert alert-info text-small">
              <p><i className="fa fa-info-circle fa-2x" aria-hidden="true"></i></p>
              <p>
                Since Facebook has discontinued their API, we've created
                the <strong>Sonlet User Plus</strong> browser extension to
                implement Facebook posting by automating browser actions
                on your behalf, and under your supervision.
              </p>
              <p>
                Please see the
                {' '}
                <a href={supportArticleUrl} target="_blank">
                  help article <i className="fa fa-external-link" aria-hidden="true"></i>
                </a>
                {' '}
                for instructions on installing and using the extension.
              </p>
              <p>
                If you need additional help, please
                send an email to <a href="mailto:support@sonlet.com">support</a>.
              </p>
            </div>
        );
    };

    const groupOptions = groups.map((group) => ({ name: group.name, value: group.fbid }));

    const albumOptions = selectedGroup
                       ? selectedGroup
                               .albums
                               .map((album) => ({
                                   name: album.name,
                                   value: album.fbid,
                               }))
                       : [];

    if (!supIsConnected) {
        return (
            <div>
              <p className="text-small">
                <i className="fa fa-circle" style={{color: "red"}}></i>
                {' '}
                <strong>Sonlet User Plus</strong> not connected
                {' '}
                <i className="fa fa-frown-o"></i>
                {' '}
                Please install the Sonlet User Plus browser extension.
                {' '}
                <button type="button"
                        className="btn btn-link btn-link-inline"
                        onClick={() => setShowHelp(!showHelp)}>Help!</button>
              </p>
              {showHelp && renderHelp()}
            </div>
        );
    }

    const nSelected = selectedItemPks.length;

    return (
        <div>
          <p className="text-small">
            <i className="fa fa-circle" style={{color: "green"}}></i>
            {' '}
            <strong>Sonlet User Plus</strong> connected <a href={supportArticleUrl} target="_blank"><i className="fa fa-info-circle"></i></a>
          </p>
          {groupOptions.length === 0 && (
              <div>
                <p className="alert alert-info text-small">
                  Looks like you haven't linked any Facebook groups yet.
                  Please link some groups to get started.
                  {' '}
                  <button type="button"
                          className="btn btn-link btn-link-inline"
                          onClick={() => setShowHelp(!showHelp)}>Help!</button>
                </p>
              </div>
          )}
          {groupOptions.length > 0 && (
              <div style={{marginBottom: "10px"}}>
                <StrSelect
                    options={groupOptions}
                    onChange={handleGroupChange}
                    placeholder="Select a group"
                    isMulti={false}
                />
              </div>
          )}
          {selectedGroup && (
              <React.Fragment>
                {selectedGroup.last_refreshed_albums && (
                    <div style={{marginBottom: "10px"}}>
                      <StrSelect
                          options={albumOptions}
                          onChange={handleAlbumChange}
                          placeholder="Select an album"
                          isMulti={false}
                      />
                    </div>
                )}
                <div style={{display: "flex"}}>
                  <div>
                    <button type="button"
                            style={{marginRight: "5px"}}
                            onClick={handleRefreshAlbums}
                            disabled={isRefreshing}
                            className="btn btn-default btn-sm"
                            title="Refresh albums">
                      <i className={`glyphicon glyphicon-refresh ${isRefreshing ? 'glyphicon-spin' : ''}`}></i>
                      {' '}
                      Refresh albums
                    </button>
                  </div>
                  <div>
                    <p className="text-small text-info">
                      {renderLastRefreshed(selectedGroup)}
                    </p>
                  </div>
                </div>
                {refreshMessage && <div className="text-info text-small">{refreshMessage}</div>}
                {selectedAlbum && (
                    <div style={{marginTop: "10px"}}>
                      <button type="button"
                              onClick={handlePost}
                              disabled={isPosting || nSelected === 0}
                              className="btn btn-primary btn-lg">
                        {isPosting && <i className="fa fa-spinner fa-pulse"></i>}
                        {isPosting ? " Posting" : "Post"} {nSelected} item{nSelected === 1 ? "" : "s"}
                      </button>
                      {postMessage && <div className="text-info text-small"
                                           style={{marginTop: "10px"}}>{postMessage}</div>}
                    </div>
                )}
              </React.Fragment>
          )}
          {showHelp && renderHelp()}
        </div>
    );
};

SUPFbPickCanvas.propTypes = {
    onPostSuccess: PropTypes.func,
};

export default function SUPFbPickCanvasApp(el, onPostSuccess) {
    if (el === null)
        return;
    const root = createRoot(el);
    root.render(<SUPFbPickCanvas onPostSuccess={onPostSuccess} />);
}
