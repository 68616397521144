import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { differenceBy } from 'lodash-es';
import moment from 'moment';

import styles from './css/fbpickshim.css';


class RefreshButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };

        this.loadGroups = this.loadGroups.bind(this);
        this.refreshQuick = this.refreshQuick.bind(this);
        this.refreshFull = this.refreshFull.bind(this);
    }

    componentDidMount() {
        this.loadGroups(false, false);
    }

    render() {
        let refreshCls = "glyphicon glyphicon-refresh";
        if (this.state.loading)
            refreshCls += " glyphicon-spin";

        const dropDownTitle = <span className={refreshCls}></span>;
        const fbAppName = is_str ? 'Sonlet' : 'PopItUp';
        let addLink = is_str
                    ? 'https://support.sonlet.com/support/solutions/articles/16000083315-add-the-sonlet-app-to-your-facebook-group-video-'
                    : 'https://support.popitup.com/support/solutions/articles/16000084270-add-the-popitup-app-to-your-facebook-group-video-';
        return (
            <div style={{display: "flex"}}>
              <div style={{flex: "initial"}}>
                <div className="btn-group">
                  <button type="button"
                          className="btn btn-default dropdown-toggle">
                    {dropDownTitle}
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="#"
                         onClick={(e) => {e.preventDefault(); this.refreshQuick();}}>Quick refresh</a>
                    </li>
                    <li>
                      <a href="#"
                         onClick={(e) => {e.preventDefault(); this.refreshFull();}}>Full refresh</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{flex: "initial", marginLeft: "10px"}}>
                {this.state.error &&
                 <div className="alert alert-danger">{this.state.error}</div>
                }
                <div className="text-muted text-small">
                  Added/deleted Facebook albums/groups lately? Click this button to sync up!
                </div>
                <div className="text-muted text-small">
                  Please remember to
                  {' '}
                  <a target="_blank" href={addLink}>add the {fbAppName} app to your group</a>
                  {' '}
                  for things to work properly.
                </div>
                <div className="text-muted text-small">
                  Only group photo albums created within the last 90
                  days can be retrieved from Facebook.
                </div>
              </div>
            </div>
        );
    }

    refreshQuick() {
        this.loadGroups(true, true);
    }

    refreshFull() {
        this.loadGroups(true, false);
    }

    loadGroups(forceRefresh=false, adminedOnly=false) {
        var data = {};
        if (forceRefresh)
            data = {'access_token': this.props.fbAccessToken, 'refresh': 1};
        if (adminedOnly)
            data.admin_only = '1';
        this.setState({loading: true});
        const groupLoadCb = (rsp) => {
            this.setState({loading: false});
            if (!rsp.success) {
                this.setState({error: 'Failed to refresh Facebook info: ' + rsp.message});
            } else {
                this.setState({error: null});
                this.props.onGroupsLoad(rsp.groups);
            }
        };
        if (this.props.loadGroups) {
            this.props.loadGroups(data, groupLoadCb);
        } else {
            $.post('/get_consultant_fb_groups/', data, groupLoadCb);
        }
    }
}

RefreshButton.propTypes = {
    fbAccessToken: PropTypes.string.isRequired,
    onGroupsLoad: PropTypes.func.isRequired,
    loadGroups: PropTypes.func,
};

const Placeholder = (props) => {
    return (
        <components.Placeholder {...props}>
          {props.children}
          <span className="text-small text-muted"
                style={{marginLeft: '20px'}}>(Hint: start typing to search)</span>
        </components.Placeholder>
    );
};

Placeholder.propTypes = {
    children: PropTypes.node,
};

const getLabel = (option) => {
    const { group } = option;
    return (
        <div className="fbpickOption">
          {group.picture_url &&
           <img src={group.picture_url} />
          }
          {group.name}
        </div>
    );
};

getLabel.propTypes = {
    option: PropTypes.object.isRequired,
};

const groupToOption = (g) => ({
    value: g.pk,
    label: g.name,
    group: g,
});

class FbPickGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            selectedGroupPk: '',
            groups: [],
        };

        this.setRef = this.setRef.bind(this);
        this.setGroups = this.setGroups.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    setRef(ref) {
        if (this.props.getRef)
            this.props.getRef(this);
    }

    setGroups(groups) {
        const preferredGroup = this.props.autoSelectPreferredGroup
                            && groups.find(g => g.is_preferred);
        const value = preferredGroup ? groupToOption(preferredGroup) : '';
        const selectedGroupPk = preferredGroup ? preferredGroup.pk : '';
        this.setState({
            groups,
            value,
            selectedGroupPk,
        }, () => {
            if (preferredGroup)
                this.props.onChange(preferredGroup);
        });
    }

    onChange(option, {action}) {
        if (action === 'clear') {
            this.setState({value: '', selectedGroupPk: ''});
            this.props.onChange(null);
            return;
        }
        /* when it's empty and you hit backspace we get this: */
        if (option.length === 0)
            return;
        this.setState({value: option, selectedGroupPk: option.group.pk});
        this.props.onChange(option.group);
    }

    render() {
        const { groups } = this.state;
        const recentGroups = groups
            .filter(g => g.last_posted)
            .sort((a, b) => moment(b.last_posted).diff(moment(a.last_posted)))
            .slice(0, 4);
        const nonRecentGroups = differenceBy(groups, recentGroups, g => g.pk);
        const adminedGroups = nonRecentGroups.filter(g => g.administrator);
        const otherGroups = nonRecentGroups.filter(g => !g.administrator);

        if ((adminedGroups.length + otherGroups.length + recentGroups.length)
            !== this.state.groups.length)
            throw "Crappy group partitioning";

        let options = [];
        if (recentGroups.length > 0)
            options.push({
                'label': 'Recent',
                options: recentGroups.map(groupToOption),
            });
        options.push({
            label: 'Groups I Admin',
            options: adminedGroups.map(groupToOption),
        });
        options.push({
            label: 'Other groups',
            options: otherGroups.map(groupToOption),
        });

        return (
            <div ref={this.setRef} className="FbPickShim">
              {this.props.selfLoad &&
               <RefreshButton fbAccessToken={this.props.fbAccessToken}
                              loadGroups={this.props.loadGroups}
                              onGroupsLoad={this.setGroups} />
              }
              <Select value={this.state.value}
                      className="groupSelect"
                      classNamePrefix="groupSelect"
                      getOptionLabel={getLabel}
                      getOptionValue={o => o.group.name}
                      onChange={this.onChange}
                      options={options}
                      placeholder="Select a group"
                      components={{ Placeholder: Placeholder }}
                      isClearable={true}
              />
              {/*
                * using getOptionLabel breaks searching, which is fixed by
                * specifying getOptionValue.  But specifying getOptionValue
                * makes the underlying input get that value (the group
                * name, in this case), but we want the group pk.  Hence
                * this extra hidden input and a lack of a `name` prop on
                * the Select.
                */}
              {this.state.selectedGroupPk &&
               <input type="hidden"
                      name="salePostGroupSel"
                      value={this.state.selectedGroupPk} />
              }
            </div>
        );
    }
}

FbPickGroup.propTypes = {
    /*
     * getRef - Underhanded little mechanism for allowing non-React code to
     * call methods on this component.
     */
    getRef: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    autoSelectPreferredGroup: PropTypes.bool,
    /*
     * selfLoad - Indicates that we'll be loading our own groups.
     *
     * When true, adds a refresh button and does the necessary network
     * requests to load Facebook groups. fbAccessToken must also be provided.
     *
     * When false, it's expected that the caller will provide a `getRef`
     * callback and use the reference to call setGroups directly with an
     * array of groups.
     */
    selfLoad: PropTypes.bool,
    fbAccessToken: PropTypes.string,
    loadGroups: PropTypes.func,
};

FbPickGroup.defaultProps = {
    autoSelectPreferredGroup: true,
    selfLoad: false,
};

export default FbPickGroup;
