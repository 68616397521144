import React from 'react';
import PropTypes from 'prop-types';
import ImageUploadOrSelect from './ImageUploadOrSelect.jsx';
import Fuse from 'fuse.js';
import { extend } from 'lodash-es';

/* some special option values */
const DONOTPOST = "-1";
const CREATENEW = "create-new";

class InitialValueSearcher {
    constructor() {
        this.album_name_transformations = {
            'os leggings': 'one size leggings',
            'os': 'one size leggings',
            'tc': 'tall and curvy leggings',
            'tc leggings': 'tall and curvy leggings',
            'lindsay': 'lindsay kimono',
            'monroe': 'monroe kimono',
            'sarah': 'sarah cardigan',
            'sara': 'sarah cardigan',
            'leggings/ os': 'one size leggings',
            'leggings/ tc': 'tall and curvy leggings',
            'classic': 'classic tee',
        };

        this.strSizesLower = typeof strSizes === 'undefined'
                           ? []
                           : strSizes.map(s => s.toLowerCase());
    }

    getAka(txt) {
        if (this.album_name_transformations.hasOwnProperty(txt))
            return this.album_name_transformations[txt];

        // strip off "all sizes", if present
        if (txt.endsWith("all sizes"))
            return txt.slice(0, txt.lastIndexOf("all sizes") - 1);

        const albumLastWord = txt.split(' ').slice(-1)[0];
        // try again without sizing
        if (this.strSizesLower.includes(albumLastWord))
            return this.getAka(txt.split(' ').slice(0, -1).join(' '));
    }

    getInitialValue(groupAlbums, candidate) {
        candidate = candidate.toLowerCase();
        const haystack = groupAlbums.map(album => {
            const name = album.name.toLowerCase();
            return extend({}, album, {
                'name': name,
                'aka': this.getAka(name),
            });
        });
        const fuse = new Fuse(haystack, {
            include: ["score"],
            shouldSort: true,
            threshold: 0.5,
            location: 0,
            distance: 0,
            maxPatternLength: 100,
            minMatchCharLength: 4,
            keys: [{
                name: "name",
                weight: 0.7,
            }, {
                name: "aka",
                weight: 0.3,
            }],
        });

        function getResult(results) {
            if (results.length > 0) {
                /* only consider the best match */
                const result = results[0];
                /* only keep it if it's > 91% confidence. 0 is a perfect match. */
                if (result.score < 0.09)
                    return result.item.pk;
            }
        }

        // HACK! the .slice is because of https://github.com/krisk/Fuse/issues/136
        const result = getResult(fuse.search(candidate.slice(0, 32)));
        if (result)
            return result;

        const candidateAka = this.getAka(candidate);
        if (candidateAka) {
            const result2 = getResult(fuse.search(candidateAka.slice(0, 32)));
            if (result2)
                return result2;
        }

        return DONOTPOST;
    }
}

class SmartPostAlbumSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: DONOTPOST,
        };
        this.handleChange = this.handleChange.bind(this);

        this.searcher = new InitialValueSearcher();
    }

    render() {
        const initial = [<option key={DONOTPOST} value={DONOTPOST}>[Do not post]</option>];
        const createNew = [<option key={CREATENEW} value={CREATENEW}>Create new...</option>];
        const choices = initial.concat(this.props.groupAlbums.map((album, idx) => {
            return (
                <option key={album.pk}
                        value={album.pk}>{album.name}{album.fbid === null && " (TO BE CREATED)"}</option>
            );
        })).concat(createNew);

        const iptName = `smartCustomAlbumSel_${this.props.inputGroupKey}`;
        const select = (
            <select className="form-control"
                    value={this.state.value}
                    name={iptName}
                    onChange={this.handleChange}>
              {choices}
            </select>
        );

        let upload = null;
        if (this.state.value === CREATENEW) {
            const iptName = `smartCustomNewAlbumName_${this.props.inputGroupKey}`;
            const imgIptName = `smartCustomNewAlbum_${this.props.inputGroupKey}`;
            upload = (
                <div>
                  <div style={{marginBottom: "10px"}}>
                    <label style={{fontSize: "12px", marginTop: "5px"}}>Album name:</label>
                    <input type="text" className="form-control"
                           name={iptName} defaultValue={this.props.candidate} />
                  </div>
                  <label style={{fontSize: "12px", marginTop: "5px"}}>Cover photo:</label>
                  <ImageUploadOrSelect inputName={imgIptName}
                                       multiple={false}
                                       showThumbnails={true} />
                </div>
            );
        }

        return (
            <div className="row">
              <div className="col-md-5">
                {select}
                {upload}
              </div>
            </div>
        );
    }

    componentDidMount() {
        //
        // WARNING!! WARNING!! MONSTROUS HACK AHEAD!!!
        //
        // We need to signal to our parent that a value has been selected
        // for the initial selection.  We can't just call this.handleChange
        // directly because our parent is in the process of rendering
        // *right now*, so if the parent calls setState on itself it won't
        // actually trigger a re-render.  So timing out for 50 ms gives the
        // parent time to finish its render, and then we can make our
        // callback on a fully attentive, initialized, and loving parent.
        //
        // TODO: figure out a less hacky way of doing this??
        //
        setTimeout(() => {
            const fakeEvent = {
                target: {
                    value: this.searcher.getInitialValue(
                        this.props.groupAlbums, this.props.candidate),
                },
            };
            this.handleChange(fakeEvent);
        }, 10);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
        const isUpload = event.target.value !== DONOTPOST;
        this.props.onChange(isUpload);
    }
}

SmartPostAlbumSelect.propTypes = {
    groupAlbums: PropTypes.array.isRequired,
    candidate: PropTypes.string.isRequired,
    inputGroupKey: PropTypes.number.isRequired,
    onChange: PropTypes.func,
};

export default SmartPostAlbumSelect;
