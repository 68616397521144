/*
 * Hello, programmer from the future!  How's the weather?
 *
 * I know this might seem like a promising component for picking
 * FB groups, but you actually really probably most likely want
 * to be using FbPickGroup.  C/ya later!
 */

import React from 'react';
import {strCompareIgnoreCase} from './util.jsx';
import PropTypes from 'prop-types';
import Select from 'react-select';


class FacebookGroupSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            value: "0",
        };
        this.didGroupsInit = false;
        this.spliceInNewGroups = this.spliceInNewGroups.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.groupsUpdated = this.groupsUpdated.bind(this);
        this.updateGroups = this.updateGroups.bind(this);
        this.refreshGroupsFromGraphApi = this.refreshGroupsFromGraphApi.bind(this);
        this.graphGroupsUpdated = this.graphGroupsUpdated.bind(this);
        this.renderLegacy = this.renderLegacy.bind(this);
        this.renderRich = this.renderRich.bind(this);
        this.handleRichChange = this.handleRichChange.bind(this);
    }

    render() {
        if (!this.props.fbIsReady)
            return null;
        if (this.props.useRichSelector)
            return this.renderRich();
        else
            return this.renderLegacy();
    }

    renderLegacy() {
        const options = this.state.groups.map((el, idx) => {
            return (
                <option key={el.id} value={el.id}>{el.name}</option>
            );
        });

        return (
            <select className="form-control" value={this.state.value} onChange={this.handleChange}>
              <option key="first" value="0" disabled>-------</option>
              {options}
            </select>
        );
    }

    renderRich() {
        const options = this.state.groups.map(group => ({
            value: { id: group.id, name: group.name },
            label: group.name,
        }));

        return (
            <Select options={options}
                    isClearable={false}
                    placeholder="Pick a group"
                    value={this.state.value}
                    onChange={this.handleRichChange}
            />
        );
    }

    componentDidMount() {
        this.updateGroups();
    }

    componentDidUpdate() {
        this.updateGroups();
    }

    updateGroups() {
        if (!this.props.fbIsReady)
            return;

        if (this.didGroupsInit)
            return;

        this.didGroupsInit = true;

        if (this.props.useGraphApi)
            this.refreshGroupsFromGraphApi();
        else
            $.get('/api/v1/get_cleanup_groups/', this.groupsUpdated);
    }

    groupsUpdated(rsp) {
        this.spliceInNewGroups(rsp);
    }

    /* this is a "controlled" component: https://facebook.github.io/react/docs/forms.html */
    handleChange(event) {
        this.setState({value: event.target.value});
        const index = event.nativeEvent.target.selectedIndex;
        const name = event.nativeEvent.target[index].text;
        if (index == 0) return;
        this.props.groupSelected({
            id: parseInt(event.target.value),
            name: name,
        });
    }

    handleRichChange(option) {
        this.setState({value: option});
        this.props.groupSelected({
            id: parseInt(option.value.id),
            name: option.value.name,
        });

    }

    spliceInNewGroups(newGroups) {
        this.setState((prevState, props) => ({
            groups: prevState.groups.concat(newGroups).sort((a, b) => {
                return strCompareIgnoreCase(a.name, b.name);
            }),
        }));
    }

    refreshGroupsFromGraphApi() {
        const url = 'me/groups?fields=id,name,administrator&limit=1000';
        window.FB.api(url, this.graphGroupsUpdated);
    }

    graphGroupsUpdated(rsp) {
        if (rsp.error) {
            this.setState({ error: rsp.error });
            return;
        }
        this.setState({ error: null });
        this.spliceInNewGroups(rsp.data.filter(g => g.administrator));
        if ((rsp.paging || {}).next)
            window.FB.api(rsp.paging.next, this.graphGroupsUpdated);
    }
}

FacebookGroupSelector.propTypes = {
    fbIsReady: PropTypes.bool.isRequired,
    groupSelected: PropTypes.func.isRequired,
    useGraphApi: PropTypes.bool,
    useRichSelector: PropTypes.bool,
};

export default FacebookGroupSelector;
