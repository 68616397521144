import React from 'react';
import { includes } from 'lodash-es';
import {strCompareIgnoreCase} from './util.jsx';
import PropTypes from 'prop-types';

class FacebookAlbumsCleanupList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            albums: [],
            strAlbums: [],
            unlinked: [],
            unlinking: [],
            error: null,
            fbAlbumsRefreshing: false,
            strAlbumsRefreshing: false,
        };
        this.albumsUpdated = this.albumsUpdated.bind(this);
        this.updateAlbums = this.updateAlbums.bind(this);
        this.unlinkAllClick = this.unlinkAllClick.bind(this);
        this.lastGroupId = null;
    }

    render() {
        const {
            albums,
            strAlbums,
            unlinked,
            unlinking,
            error,
            fbAlbumsRefreshing,
            strAlbumsRefreshing,
        } = this.state;
        const site_brand_name = is_str ? "Sonlet" : "PopItUp";

        if (fbAlbumsRefreshing || strAlbumsRefreshing) {
            return (
                <div>
                  <i className="fa fa-refresh fa-spin fa-fw"></i>
                  {fbAlbumsRefreshing && <p className="text text-info">Refreshing Facebook albums</p>}
                  {strAlbumsRefreshing && <p className="text text-info">Refreshing {site_brand_name} albums</p>}
                </div>
            );
        }

        if (!this.props.group)
            return <p className="text-info">No albums</p>;

        const fbAlbumIds = albums.map((el) => el.id);
        const unlinkingIds = unlinking.map((el) => el.id);
        const unlinkedIds = unlinked.map((el) => el.id);

        function makeRowForOne(validOnFb, el) {
            const fontWeight = validOnFb ? 'normal' : 'bold';
            const iconCls = includes(unlinkingIds, el.id) ? "fa fa-refresh fa-spin fa-fw" : "fa fa-chain-broken";
            const isUnlinked = includes(unlinkedIds, el.id);
            const nameStyle = isUnlinked ? {textDecoration: "line-through"} : {};
            const albumLink = `https://www.facebook.com/${el.id}`;
            const unlinkBtnClasses = "btn btn-sm unlinkButton " + (
                validOnFb ? "btn-danger" : "btn-success");
            const unlinkBtn = (
                <button className={unlinkBtnClasses}
                        onClick={this.unlinkClick.bind(this, el)} >
                  <i className={iconCls} aria-hidden="true"></i> Unlink
                </button>
            );
            return (
                <tr key={el.id} style={{fontWeight}}>
                  <td style={{padding: '3px 10px'}}>
                    {isUnlinked && "Unlinked"}
                    {!isUnlinked && unlinkBtn}
                  </td>
                  <td>
                    <span style={nameStyle}>
                      <a target="_blank"
                         href={albumLink}>{el.name}</a>
                    </span>
                  </td>
                </tr>
            );
        }

        const strValidAlbumsList = strAlbums
            .filter((el) => includes(fbAlbumIds, el.id))
            .map(makeRowForOne.bind(this, true));

        const strInvalidAlbumsList = strAlbums
            .filter((el) => !includes(fbAlbumIds, el.id))
            .map(makeRowForOne.bind(this, false));

        return (
            <div>
              {error !== null &&
               <p className="text text-danger">Error: {error.message}</p>
              }
              {is_str &&
               <div>
                 <p>Unlink from:</p>
                 <label className="checkbox-inline">
                   <input type="checkbox" id="delete-from-listings" defaultChecked /> Listings
                 </label>
                 <label className="checkbox-inline">
                   <input type="checkbox" id="delete-from-sales" defaultChecked /> Sales
                 </label>
               </div>
              }
              <h6>Albums deleted from Facebook (or inaccessible)</h6>
              {strInvalidAlbumsList.length > 0 &&
               <div>
                 <p>
                   {site_brand_name} has posted to the following albums which
                   don't appear to exist on Facebook anymore or are
                   now inaccessible. These are good candidates for
                   unlinking.
                 </p>
                 <button className="btn btn-sm btn-success"
                         onClick={this.unlinkAllClick} >
                   <i className="fa fa-chain-broken" aria-hidden="true"></i> Unlink All
                 </button>
                 <table id="invalidAlbumsTable">
                   <tbody>
                     {strInvalidAlbumsList}
                   </tbody>
                 </table>
               </div>
              }
              {strInvalidAlbumsList.length === 0 &&
               <p className="text-info">No albums</p>
              }
              <hr />
              <h6>Albums still on Facebook</h6>
              {strValidAlbumsList.length > 0 &&
               <div>
                 <p>
                   {site_brand_name} has posted to the following albums which <em>do</em> still
                   exist on Facebook. You can unlink these if you really want to,
                   but remember that once unlinked, {site_brand_name} won't be
                   able to delete or refresh Facebook comments for photos in the
                   unlinked albums.  So you should only unlink these if you
                   really know what you're doing.
                 </p>
                 <table>
                   <tbody>
                     {strValidAlbumsList}
                   </tbody>
                 </table>
               </div>
              }
              {strValidAlbumsList.length === 0 &&
               <p className="text-info">No albums</p>
              }
            </div>
        );
    }

    albumsUpdated(rsp) {
        if (rsp.error) {
            this.setState({
                error: rsp.error,
                fbAlbumsRefreshing: false,
            });
            return;
        }

        this.setState({error: null});

        this.spliceInNewAlbums(rsp.data);

        /* fun stuff: http://stackoverflow.com/a/4034468/209050 */
        if ((rsp.paging || {}).next)
            window.FB.api(rsp.paging.next, this.albumsUpdated);
        else
            this.setState({fbAlbumsRefreshing: false});
    }

    componentDidMount() {
        this.updateAlbums();
    }

    componentDidUpdate() {
        this.updateAlbums();
    }

    updateAlbums() {
        if (!this.props.group)
            return;
        if (this.lastGroupId === this.props.group.id)
            return;

        this.setState({
            fbAlbumsRefreshing: true,
            strAlbumsRefreshing: true,
        });

        this.lastGroupId = this.props.group.id;
        window.FB.api(this.props.group.id + '/albums?limit=1000', this.albumsUpdated);

        const self = this;
        const url = `/api/v1/posted_albums_by_group/${this.props.group.id}/`;
        $.getJSON(url, function(data) {
            self.setState({
                strAlbumsRefreshing: false,
                strAlbums: data.albums.sort((a, b) => {
                    return strCompareIgnoreCase(a.name, b.name);
                }),
            });
        });
    }

    spliceInNewAlbums(newAlbums) {
        this.setState((prevState, props) => ({
            albums: prevState.albums.concat(newAlbums).sort((a, b) => {
                return strCompareIgnoreCase(a.name, b.name);
            }),
        }));
    }

    unlinkClick(album) {
        this.setState((prevState, props) => ({
            unlinking: prevState.unlinking.concat([album]),
        }));
        const url = '/api/v1/unlink_album/';
        const data = {
            'fbid': album.id,
            'unlink_listings': $("#delete-from-listings").is(":checked"),
            'unlink_sales': $("#delete-from-sales").is(":checked"),
        };
        const self = this;
        $.post(url, data, function() {
            self.setState((prevState, props) => ({
                unlinking: prevState.unlinking.filter((el) => el.id === album.id),
                unlinked: prevState.unlinked.concat([album]),
            }));
        });
    }

    unlinkAllClick() {
        /* cheating with jquery! arrays of refs are clumsy... */
        $("#invalidAlbumsTable .unlinkButton").click();
    }
}

FacebookAlbumsCleanupList.propTypes = {
    group: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
};

export default FacebookAlbumsCleanupList;
